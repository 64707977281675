
import { ref, onMounted, nextTick, defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    plan: string;
    status: string;
  };
}
export default defineComponent({
  components: {
    KTDatatable,
  },
  props: {
    customerData: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore(); // Access the Vuex store
    const loading = ref<boolean>(true);
    const customer = ref(props.customerData);
    const headerConfig = ref([
      {
        name: "ID",
        key: "id",
      },
      {
        name: "Invoice Number",
        key: "invoice_number",
      },
      {
        name: "Paid At",
        key: "due_date",
      },
      {
        name: "Plan",
        key: "plan",
      },
      {
        name: "Amount",
        key: "total_amount",
      },
      {
        name: "# of Bins",
        key: "no_of_bins",
      },
      {
        name: "Status",
        key: "status",
      },
    ]);
    const total = ref<number>(100);
    const billingTableData = ref<any[]>([]);
    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        plan: "",
        status: "all",
      },
    });
    const plan = ref<any[]>([]);
    const status = ref<any[]>([]);
    // Method to get subscription type based on customer ID
    const getStatusClass = (status) => {
      return `status-style ${status.toLowerCase()}`;
    };
    const getLabelClass = (status) => {
      return `${status.toLowerCase()}-label`;
    };
    const formatDate = (val) => {
      // Check if the value is a valid date
      if (val) {
        // Convert the value to a Date object
        const date = new Date(val);

        // Ensure the date is valid
        if (isNaN(date.getTime())) {
          return "Invalid Date"; // Return a fallback if the date is invalid
        }

        // Use Intl.DateTimeFormat to format the date
        return new Intl.DateTimeFormat("en-US", {
          weekday: "short", // Mon
          year: "numeric", // 2025
          month: "short", // Mar
          day: "2-digit", // 17
          hour: "2-digit", // 22
          minute: "2-digit", // 05
          second: "2-digit", // 59
          hour12: true, // 12-hour format
        }).format(date);
      } else {
        // Return a default value if the input is undefined, null, or invalid
        return "---";
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Billing", [""]);
      loading.value = true;
      billListApi();
    });
    async function billListApi() {
      loading.value = true;
      let payload = {
        page_number: pagination.value.page_number,
        page_size: pagination.value.page_size,
        status: pagination.value.filters.status,
        id: props.customerData.id,
      };
      await store
        .dispatch("BillingModule/paymentDetailsApi", payload)
        .then((res) => {
          billingTableData.value = res.data;
          loading.value = false;
        });
    }
    async function cancelSubscriptionPlan(){
        await store.dispatch("BillingModule/cancelApi").then((res)=>{
            console.log("Check the res",res);
            if(res.message == 'Active subscription not found'){
                Swal.fire({
            text: "Active subscription not found",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
            }
        })
    }

    const currentChange = (val) => {
      pagination.value.page_number = val;
      billListApi();
    };
    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      billListApi();
    };

    // Return data and methods to be used in the template
    return {
      plan,
      status,
      pagination,
      billingTableData,
      total,
      headerConfig,
      loading,
      getLabelClass,
      getStatusClass,
      currentChange,
      itemsPerPageChange,
      formatDate,
      customer,
      cancelSubscriptionPlan
    };
  },
});
