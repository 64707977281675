import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 col-sm-6 col-md-4 col-lg-4" }
const _hoisted_4 = { class: "input-group set-input-width" }
const _hoisted_5 = { class: "input-group-text" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "col-12 col-sm-6 col-md-2 col-lg-2" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "col-12 col-sm-6 col-md-2 col-lg-2" }
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "row" }
const _hoisted_13 = {
  class: "card-body pt-0",
  style: {"width":"100%"}
}
const _hoisted_14 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTDatatable = _resolveComponent("KTDatatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createCommentVNode(" Search Input Column "),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[4] || (_cache[4] = _createElementVNode("input", {
            type: "text",
            class: "form-control",
            placeholder: "Search",
            "aria-label": "Search",
            "aria-describedby": "inputGroup-sizing-lg"
          }, null, -1 /* HOISTED */)),
          _createElementVNode("span", _hoisted_5, [
            _createElementVNode("img", { src: $setup.SearchIcon }, null, 8 /* PROPS */, _hoisted_6)
          ])
        ])
      ]),
      _createCommentVNode(" First Select Dropdown Column "),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _withDirectives(_createElementVNode("select", {
            class: "form-select border border-primary form-select-solid drop-down-set-layout",
            "data-control": "select2",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.onCustomerChange && $setup.onCustomerChange(...args))),
            "data-dropdown-parent": "#kt_modal_1",
            "data-placeholder": "Select an option",
            "data-allow-clear": "true",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.pagination.filters.plan) = $event))
          }, [
            _cache[5] || (_cache[5] = _createElementVNode("option", {
              value: "",
              disabled: "",
              selected: ""
            }, "Plan", -1 /* HOISTED */)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.plan, (pln, i) => {
              return (_openBlock(), _createElementBlock("option", {
                key: i,
                value: pln.name
              }, _toDisplayString(pln.name), 9 /* TEXT, PROPS */, _hoisted_9))
            }), 128 /* KEYED_FRAGMENT */))
          ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
            [_vModelSelect, $setup.pagination.filters.plan]
          ])
        ])
      ]),
      _createCommentVNode(" Second Select Dropdown Column "),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("select", {
            class: "form-select border border-primary form-select-solid drop-down-set-layout",
            "data-control": "select2",
            onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.onCustomerChange && $setup.onCustomerChange(...args))),
            "data-dropdown-parent": "#kt_modal_1",
            "data-placeholder": "Select an option",
            "data-allow-clear": "true",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.pagination.filters.status) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.status, (status, i) => {
              return (_openBlock(), _createElementBlock("option", {
                key: i,
                value: status.value
              }, _toDisplayString(status.name), 9 /* TEXT, PROPS */, _hoisted_11))
            }), 128 /* KEYED_FRAGMENT */))
          ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
            [_vModelSelect, $setup.pagination.filters.status]
          ])
        ])
      ])
    ]),
    _createCommentVNode(" Billing Table Code Start here "),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_KTDatatable, {
          "table-data": $setup.billingTableData,
          total: $setup.total,
          "table-header": $setup.headerConfig,
          "enable-items-per-page-dropdown": true,
          loading: $setup.loading,
          rowsPerPage: $setup.pagination.page_size,
          onCurrentChange: $setup.currentChange,
          onItemsPerPageChange: $setup.itemsPerPageChange
        }, {
          "cell-id": _withCtx(({ row: billing }) => [
            _createElementVNode("div", null, _toDisplayString(billing.customer.id), 1 /* TEXT */)
          ]),
          "cell-customer_name": _withCtx(({ row: billing }) => [
            _createElementVNode("div", null, _toDisplayString(billing.customer.name), 1 /* TEXT */)
          ]),
          "cell-subscription_plan": _withCtx(({ row: billing }) => [
            _createElementVNode("div", null, _toDisplayString($setup.subscriptionPlan(billing.customer.id)), 1 /* TEXT */)
          ]),
          "cell-subscription_amount": _withCtx(({ row: billing }) => [
            _createElementVNode("div", null, _toDisplayString($setup.subscriptionAmount(billing.customer.id)), 1 /* TEXT */)
          ]),
          "cell-next_payment_Date": _withCtx(({ row: billing }) => [
            _createElementVNode("div", null, _toDisplayString($setup.subscriptionRenewal(billing.customer.id)), 1 /* TEXT */)
          ]),
          "cell-subscription_status": _withCtx(({ row: billing }) => [
            _createElementVNode("div", {
              class: _normalizeClass($setup.getStatusClass($setup.subscriptionStatus(billing.customer.id)))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass($setup.getLabelClass($setup.subscriptionStatus(billing.customer.id)))
              }, _toDisplayString($setup.subscriptionStatus(billing.customer.id)), 3 /* TEXT, CLASS */)
            ], 2 /* CLASS */)
          ]),
          "cell-action": _withCtx(({ row: billing }) => [
            _createElementVNode("div", {
              class: "subscription_details",
              onClick: ($event: any) => ($setup.openCustomerDetails(billing.customer))
            }, " View Details ", 8 /* PROPS */, _hoisted_14)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange"])
      ])
    ])
  ]))
}