
import customerDetail from '@/components/billing/customerDetails.vue'
import paymentDetails from '@/components/billing/paymentDetails.vue'
import {ref,defineComponent} from "vue";
import backArrow from "@/assets/img/billing-img/back-arrow.svg"
export default defineComponent({
  name: "apps-administration-billing",
  components:{
    customerDetail,
    paymentDetails
  },
  setup(){
    const backImg = backArrow;
    const currentView = ref<string>('customerDetailView');
   const customerData = ref<any[]>([]);
    const openPaymentPage = (val)=> {
      console.log("emit call *****",typeof(val))
      customerData.value = val
      currentView.value = 'paymentDetailView';
      console.log("id **",typeof(customerData.value))
    }
    return {
      currentView,
      openPaymentPage,
      customerData,
      backImg
    }

  }

});
