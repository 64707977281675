import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "adjust-back-btn-position d-flex" }
const _hoisted_4 = { class: "me-2" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_customer_detail = _resolveComponent("customer-detail")!
  const _component_paymentDetails = _resolveComponent("paymentDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.currentView == 'paymentDetailView')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentView = 'customerDetailView'))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("img", {
                  class: "",
                  src: _ctx.backImg
                }, null, 8 /* PROPS */, _hoisted_5)
              ]),
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "ms-2" }, "Back", -1 /* HOISTED */))
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", null, [
        (_ctx.currentView == 'customerDetailView')
          ? (_openBlock(), _createBlock(_component_customer_detail, {
              key: 0,
              onOpenPaymentPage: _ctx.openPaymentPage
            }, null, 8 /* PROPS */, ["onOpenPaymentPage"]))
          : _createCommentVNode("v-if", true),
        (_ctx.currentView == 'paymentDetailView')
          ? (_openBlock(), _createBlock(_component_paymentDetails, {
              key: 1,
              customerData: _ctx.customerData
            }, null, 8 /* PROPS */, ["customerData"]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}