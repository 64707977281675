
import { ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import searchImg from "../../assets/img/billing-img/icon-wrapper.svg";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    plan: string;
    status: string;
  };
}
export default {
  name: "apps-administration-billing",
  components: {
    KTDatatable,
  },
  setup(props, { emit }) {
    const store = useStore(); // Access the Vuex store
    const loading = ref<boolean>(true);
    const headerConfig = ref([
      {
        name: "ID",
        key: "id",
      },
      {
        name: "Customer Name",
        key: "customer_name",
      },
      {
        name: "Plan",
        key: "subscription_plan",
      },
      {
        name: "Amount",
        key: "subscription_amount",
      },
      {
        name: "Renewal Date",
        key: "next_payment_Date",
      },
      {
        name: "Status",
        key: "subscription_status",
      },
      {
        name: "Action",
        key: "action",
      },

    ]);
    const total = ref<number>(100);
    const billingTableData = ref<any[]>([]);
    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        plan: "",
        status: "all",
      },
    });
    const plan = ref<any[]>([]);
    const status = ref<any[]>([]);
    const SearchIcon = searchImg;
    // Method to get subscription type based on customer ID
    const getStatusClass = (status) => {
      return `status-style ${status.toLowerCase()}`;
    };
    const getLabelClass = (status)=>{
       return `${status.toLowerCase()}-label`; 
    }
    const subscriptionPlan = (customerId) => {
      const subscription = billingTableData.value
        .flatMap((item) => item.subscriptions)
        .find((subscription) => subscription.customerId === customerId);
      return subscription ? subscription.metadata.subscription_type : "---";
    };
    const subscriptionAmount = (customerId) => {
      const subscription = billingTableData.value
        .flatMap((item) => item.subscriptions)
        .find((subscription) => subscription.customerId === customerId);
      return subscription
        ? subscription.amount.currency + " " + subscription.amount.value
        : "---";
    };
    const subscriptionRenewal = (customerId) => {
      const subscription = billingTableData.value
        .flatMap((item) => item.subscriptions)
        .find((subscription) => subscription.customerId === customerId);
      return subscription ? subscription.nextPaymentDate : "---";
    };
    const subscriptionStatus = (customerId) => {
      const subscription = billingTableData.value
        .flatMap((item) => item.subscriptions)
        .find((subscription) => subscription.customerId === customerId);
      return subscription ? subscription.status : "---";
    };
    onMounted(() => {
      billListApi();
      setCurrentPageBreadcrumbs("Billing", [""]);
      // You can perform other actions here, such as fetching data or initializing libraries
      plan.value.push(
        { name: "Essential" },
        { name: "Pro" },
        { name: "Vision AI" }
      );
      status.value.push(
        {
            name : "All",
            value:"all"
        },
        {
          name: "paid",
          value:"paid"
        },
        {
          name: "unpaid",
          value:"unpaid"
        },
        {
          name: "expired",
          value:"expired"
        }
      ) 
    });
    const onCustomerChange = () => {
        billListApi();
     
    };
    const openCustomerDetails = (val)=>{
      emit('openPaymentPage',val);
    }
    async function billListApi() {
      let payload = {
        page_number: pagination.value.page_number,
        page_size: pagination.value.page_size,
        status: pagination.value.filters.status,
      };
      await store
        .dispatch("BillingModule/CustomerBillingListApi", payload)
        .then((res) => {
          billingTableData.value = res.data;
          loading.value= false;
        });
    }
        
    const currentChange = (val) => {
      pagination.value.page_number = val;
      billListApi();
    };
    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      billListApi();
    };

    // Return data and methods to be used in the template
    return {
      SearchIcon,
      plan,
      status,
      pagination,
      billingTableData,
      total,
      headerConfig,
      loading,
      getLabelClass,
      getStatusClass,
      subscriptionStatus,
      subscriptionRenewal,
      subscriptionAmount,
      subscriptionPlan,
      currentChange,
      itemsPerPageChange,
      onCustomerChange,
      openCustomerDetails
    };
  },
};
