import { createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "d-flex justify-content-between" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12 col-sm-6 col-md-3 col-lg-3" }
const _hoisted_6 = { class: "sub-head-content-sty" }
const _hoisted_7 = { class: "col-12 col-sm-6 col-md-3 col-lg-3" }
const _hoisted_8 = { class: "sub-head-content-sty" }
const _hoisted_9 = { class: "col-12 col-sm-6 col-md-3 col-lg-3" }
const _hoisted_10 = { class: "sub-head-content-sty" }
const _hoisted_11 = { class: "col-12 col-sm-6 col-md-3 col-lg-3" }
const _hoisted_12 = { class: "sub-head-content-sty" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = {
  class: "card-body pt-0",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTDatatable = _resolveComponent("KTDatatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "main-heading-sty" }, "Payment details", -1 /* HOISTED */)),
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancelSubscriptionPlan && _ctx.cancelSubscriptionPlan(...args))),
            class: "btn btn-primary"
          }, "Cancel Subscription")
        ])
      ])
    ]),
    _createCommentVNode(" Divider Code start here "),
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "mt-4" }, [
      _createElementVNode("hr", { class: "dropdown-divider" })
    ], -1 /* HOISTED */)),
    _createCommentVNode(" Detail data content start here "),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "sub-head-sty" }, "Company Id", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.customer.id), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "sub-head-sty" }, "Company name", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.customer.name), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "sub-head-sty" }, "Company Email", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.customer.email), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "sub-head-sty" }, "Subscription Mode", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.customer.mode), 1 /* TEXT */)
      ])
    ]),
    _createCommentVNode(" Divider Code start here "),
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "mt-4" }, [
      _createElementVNode("hr", { class: "dropdown-divider" })
    ], -1 /* HOISTED */)),
    _createCommentVNode(" Billing Table Code Start here "),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_KTDatatable, {
          "table-data": _ctx.billingTableData,
          total: _ctx.total,
          "table-header": _ctx.headerConfig,
          "enable-items-per-page-dropdown": true,
          loading: _ctx.loading,
          rowsPerPage: _ctx.pagination.page_size,
          onCurrentChange: _ctx.currentChange,
          onItemsPerPageChange: _ctx.itemsPerPageChange
        }, {
          "cell-id": _withCtx(({ row: billing }) => [
            _createElementVNode("div", null, _toDisplayString(billing.customerId ? billing.customerId : "---"), 1 /* TEXT */)
          ]),
          "cell-invoice_number": _withCtx(({ row: billing }) => [
            _createElementVNode("div", null, _toDisplayString(billing.id), 1 /* TEXT */)
          ]),
          "cell-due_date": _withCtx(({ row: billing }) => [
            _createElementVNode("div", null, _toDisplayString(_ctx.formatDate(billing.paidAt)), 1 /* TEXT */)
          ]),
          "cell-plan": _withCtx(({ row: billing }) => [
            _createElementVNode("div", null, _toDisplayString(billing.metadata.subscription_type), 1 /* TEXT */)
          ]),
          "cell-total_amount": _withCtx(({ row: billing }) => [
            _createElementVNode("div", null, _toDisplayString(billing.amount.currency + " " + billing.amount.value), 1 /* TEXT */)
          ]),
          "cell-no_of_bins": _withCtx(({ row: billing }) => [
            _createElementVNode("div", null, _toDisplayString(billing.metadata.number_of_devices), 1 /* TEXT */)
          ]),
          "cell-status": _withCtx(({ row: billing }) => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.getStatusClass(billing.status))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.getLabelClass(billing.status))
              }, _toDisplayString(billing.status), 3 /* TEXT, CLASS */)
            ], 2 /* CLASS */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange"])
      ])
    ])
  ]))
}